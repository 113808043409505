article {
  position: relative;
  margin-top: 50px;

  @include tablet {
    margin-top: 60px;
  }

  aside {
    pointer-events: all;

    @include touch {
      &:has(*) {
        margin-bottom: 30px;
      }
    }

    @include tablet {
      position: absolute;
      min-height: 100%;
      width: 33.3333333333%;
      top: 0;

      .aside-sticky {
        position: sticky;
      }
    }

    @include desktop {
      width: calc(25% - 5px);
    }

    &.is-left {
      left: 0;

      @include tablet {
        padding-right: 10px;
      }

      .aside-sticky {
        top: 140px;
      }

      .buttons.is-vertical {
        .button {
          &:hover {
            background: $white;
            color: $primary;
            border-color: $grey;
          }
        }
      }
    }

    &.is-right {
      right: 0;

      @include tablet {
        padding-left: 10px;
      }

      .aside-sticky {
        top: 40px;
        transition: opacity 0.75s cubic-bezier(0.4, 0, 0.25, 1);

        &.is-transparent {
          opacity: 0;
          transition-duration: 0.15s;
          pointer-events: none;
        }
      }
    }

    .menu {
      padding: 20px 30px;

      a {
        line-height: 1.125;
        padding: 5px 0;
      }
    }
  }

  .article-body {
    margin-bottom: 40px;
    color: $grey-darker;

    [class^='block-']:first-child {
      margin-top: 0 !important;
    }

    [class^='block-']:last-child {
      margin-bottom: 0 !important;
    }

    .content a {
      text-decoration: none;
      color: $primary;

      &:hover {
        text-decoration: underline;
      }
    }

    .block-heading_block {
      margin-top: 3.75rem;
      margin-bottom: 2rem;
    }

    .block-subtitle_block {
      margin-top: 2.5rem;

      & + * {
        margin-top: 0.5rem !important;
      }
    }

    .block-text,
    .block-paragraph_block {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .block-image,
    .block-image_block {
      margin-top: 3.75rem;
      margin-bottom: 3.75rem;

      .caption {
        margin: 20px 0 0 20px;
        font-style: italic;
      }
    }

    .block-image .caption {
      margin-top: 10px;
    }

    .block-persons,
    .block-contact_cards {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }

    .block-logo_grid {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;

      img {
        display: block;
        height: 60px;

        @include desktop {
          &:not(:hover) {
            filter: grayscale(1);
          }
        }
      }
    }

    .block-documents {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .block-accordion {
      margin-top: 2rem;
      margin-bottom: 2rem;

      .accordion-container {
        // margin-left: -20px;
        // margin-right: -20px;

        &:not(:first-child) {
          margin-top: 10px;
        }
      }

      .accordion-trigger {
        min-height: 80px;
        height: auto;
        padding: 20px;
        text-align: left;

        .button {
          flex: none;
        }

        &.is-active {
          background: $white;
          border-bottom: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          .icon {
            transform: rotate(-90deg);
          }
        }

        &:hover,
        &.is-active {
          border-color: $primary;
          color: $primary;

          > .button.is-black {
            background: $primary;
          }
        }
      }

      .accordion-body {
        overflow: hidden;
        background: $white;
        padding: 10px 20px 30px;
        border: 1px solid $primary;
        border-top: 0;
        border-radius: 0 0 $radius $radius;

        > [class^='block-'] {
          margin-top: 1.25rem;
          margin-bottom: 1.25rem;
        }

        .is-limited {
          margin-left: 0;
          margin-right: 0;
          width: auto;
        }
      }
    }

    .block-carousel {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }

    .block-gallery_thumbnail,
    .block-gallery_grid {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }

    .block-gallery_grid {
      > .columns {
        background: $grey-lighter;
        position: relative;
        z-index: 2;
        flex: 1;

        .image,
        .box,
        img {
          height: 100%;
        }
      }
    }

    .block-embed_block {
      margin-top: 2rem;
      margin-bottom: 2rem;

      .image {
        background: $grey-darker;

        > :first-child {
          width: 100%;
          height: 100%;
          position: absolute;
          right: 0;
          bottom: 0;
          top: 0;
          left: 0;
        }
      }
    }

    .block-links,
    .block-pages_links {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }

    .block-button {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .block-block_quote {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;

      .content {
        font-style: italic;
      }

      .image {
        width: 60px;
        height: 60px;
        filter: grayscale(1);
      }
    }

    .block-timeline {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;

      header {
        background: $grey;
        border-radius: $radius-control $radius-control 0 0;
        padding: 6px 16px;
        color: $black;

        & + .box {
          border-radius: 0 0 $radius-control $radius-control;
        }
      }

      .box {
        padding: 10px 20px;

        // &:not(:last-child) {
        //   margin-bottom: 30px;
        // }

        > .columns:not(:last-child) {
          margin-bottom: 0;
        }

        > .columns:first-child > .column {
          padding-top: 20px;
        }

        > .columns:last-child > .column {
          padding-bottom: 20px;
        }

        .content {
          padding-top: 2px;
        }
      }

      .column.is-bordered {
        border-left: 1px solid $grey;
      }
    }

    .block-map_block {
      margin-top: 3.75rem;
      margin-bottom: 3.75rem;
    }
  }
}
