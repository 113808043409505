@font-face {
  font-family: 'basier_square';
  src: url('basiersquare-regular-webfont.eot');
  src: url('basiersquare-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('basiersquare-regular-webfont.woff2') format('woff2'),
       url('basiersquare-regular-webfont.woff') format('woff'),
       url('basiersquare-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'basier_square';
  src: url('basiersquare-regularitalic-webfont.eot');
  src: url('basiersquare-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('basiersquare-regularitalic-webfont.woff2') format('woff2'),
       url('basiersquare-regularitalic-webfont.woff') format('woff'),
       url('basiersquare-regularitalic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'basier_square';
  src: url('basiersquare-medium-webfont.eot');
  src: url('basiersquare-medium-webfont.eot?#iefix') format('embedded-opentype'),
       url('basiersquare-medium-webfont.woff2') format('woff2'),
       url('basiersquare-medium-webfont.woff') format('woff'),
       url('basiersquare-medium-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'basier_square';
  src: url('basiersquare-semibold-webfont.eot');
  src: url('basiersquare-semibold-webfont.eot?#iefix') format('embedded-opentype'),
       url('basiersquare-semibold-webfont.woff2') format('woff2'),
       url('basiersquare-semibold-webfont.woff') format('woff'),
       url('basiersquare-semibold-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
