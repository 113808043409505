body {
  height: auto;
  min-height: 100%;

  @include touch {
    &:not(.is-full) {
      padding-top: 6.25rem;
    }
  }
}

.container.is-fluid {
  max-width: $fullhd !important;

  .is-limited {
    margin-left: auto;

    @include tablet {
      padding-left: 10px;
      padding-right: 10px;
      width: 66.6666666667%;
      margin-right: 0;
    }

    @include desktop {
      width: 50%;
      margin-right: auto;

      &.is-right {
        margin-left: auto;
        margin-right: 0;
        padding-right: 0;
        width: 75%;
      }
    }
  }

  .is-centered .is-limited {
    @include tablet {
      width: 100%;
      margin-right: auto;
    }

    @include desktop {
      width: 50%;
    }
  }
}

.image {
  img {
    &.is-rounded {
      border-radius: $radius;
    }
  }

  &.has-fullscreen {
    .button.is-expand {
      z-index: 1;
      position: absolute;
      bottom: 20px;
      right: 20px;
    }

    &.is-hoverable {
      @include desktop {
        &:not(:hover) {
          .button.is-expand {
            display: none;
          }
        }
      }
    }
  }

  &.is-fullheight {
    height: 100%;
  }
}

@include mobile {
  .columns {
    &.is-mobile-first-to-last {
      display: flex;
      flex-direction: column;

      > .column {
        &:first-child {
          order: 1;
        }
      }
    }
  }

  section.is-full-mobile {
    margin-left: -$gap;
    margin-right: -$gap;
  }
}
