#featured-pages {
  position: relative;
  height: 100vh;
  height: 100svh;

  @include desktop {
    height: auto;
  }

  .carousel-container {
    .carousel-item {
      width: auto;
      height: auto;
    }
  }

  .image {
    img {
      opacity: 0;
      transition: opacity 0.5s;

      &.is-visible {
        opacity: 1;
      }
    }
  }

  footer {
    z-index: 1;
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;

    .box {
      position: relative;
      border: 1px solid #e4e4e4;

      .carousel {
        // .flickity-viewport {
        //   transition: height 0.25s cubic-bezier(0.4, 0, 0.25, 1);
        // }

        .carousel-item {
          padding: 20px 20px 68px 20px;
          width: 100%;

          @include desktop {
            padding-right: 130px;
          }
        }

        .buttons.is-nav {
          position: absolute;
          right: 20px;
          bottom: 20px;
        }
      }
    }

    @include tablet {
      bottom: 40px;

      .box {
        width: 50%;
      }
    }
  }
}

.carousel {
  .carousel-container {
    .carousel-item {
      width: 100%;
      height: 100%;
    }

    .image {
      height: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  &.is-default {
    .carousel-container {
      border-radius: $radius;
      overflow: hidden;
      background: $grey-light;
      opacity: 0;
      transition: opacity 0.4s;
      aspect-ratio: 3/2;

      &.flickity-enabled {
        opacity: 1;
      }
    }

    footer {
      padding: 1.25rem;
      color: $grey-darker;
      position: relative;

      .is-expand {
        position: absolute;
        top: -60px;
        right: 20px;
        z-index: 1;
      }
    }
  }

  &.is-fullscreen {
    .carousel-container {
      z-index: 99;
      border-radius: 0;
      background: $black;
      height: 100%;
      padding-bottom: 0;

      .carousel-item {
        padding: 10px 10px 100px;

        @include tablet {
          padding: 80px 100px 120px;
        }

        .image {
          overflow: hidden;
          touch-action: auto;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            border-radius: $radius;
            width: auto;
            height: auto;
            max-height: 100%;
            max-width: 100%;
            object-fit: contain;
          }
        }
      }
    }

    footer {
      position: fixed;
      bottom: 46px;
      left: 30px;
      right: 30px;
      min-height: 40px;
      z-index: 100;
      color: $grey;

      .wrapper {
        @include mobile {
          p {
            background: $black;
            border-radius: $radius-small;
            padding: 0.5rem;
          }
        }
      }

      .buttons {
        justify-content: flex-end;
      }

      @include tablet {
        width: 100%;
        max-width: 50%;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }

  header.is-main &.is-default {
    height: 100%;

    .carousel-container {
      aspect-ratio: auto;
      padding-top: 66.6666667%;
      min-height: 100%;

      @include mobile {
        border-radius: $radius $radius 0 0;
      }

      .flickity-viewport {
        position: absolute;
        top: 0;
        width: 100%;

        .carousel-item {
          height: 100%;
        }
      }
    }

    footer {
      position: absolute;
      right: 0;

      .column:has(.wrapper) {
        display: none;
      }

      @include tablet {
        right: 20px;
      }
    }
  }
}
