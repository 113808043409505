nav.navbar {
  padding-top: 40px;

  &.is-main {
    top: 0;
    left: 0;
    right: 0;

    @include desktop {
      body.is-full & {
        left: 0;
        right: 0;
        position: absolute;

        &.is-active {
          position: fixed;
        }
      }

      &.is-active {
        position: sticky;
      }
    }

    @include touch {
      position: fixed;

      .container {
        .navbar-brand {
          .box {
            height: $navbar-height;
            padding: 10px;
          }

          .box svg {
            height: 33px;
            width: 90px;
          }

          .buttons {
            flex-wrap: nowrap;
          }
        }

        .is-burger {
          &:not(.is-active) {
            .button.is-close {
              display: none;
            }
          }

          &.is-active {
            flex: 1;

            .button {
              background: $primary;
              color: $white;
            }
          }
        }

        .navbar-menu {
          position: fixed;
          background: $background;
          z-index: -1;
          padding: 100px 30px 30px;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          max-height: none;
          overflow: auto;
        }
      }
    }

    .button.is-active {
      background: $black;
      color: $white;
    }
  }

  &.is-mini {
    transform: translateY(-250%);
    transition: transform 0.35s cubic-bezier(0.4, 0, 0.25, 1);
    z-index: 29;
    pointer-events: none;

    .container {
      display: flex;

      .navbar-brand {
        pointer-events: all;

        .box {
          height: $navbar-height;
          padding: 10px;
        }

        .box svg {
          height: 33px;
          width: 90px;
        }
      }

      .buttons {
        pointer-events: all;
      }
    }

    &.is-active {
      transform: translateY(0);
      transition-duration: 0.75s;
    }
  }

  .container {
    align-items: flex-end;

    .navbar-brand {
      margin-left: 0;

      .box {
        color: $white;
        display: flex;
        border-radius: $radius-small;
        border: 1px solid $black;
        background: $primary;
        align-items: flex-end;
        padding: 16px;
        height: 118px;
        margin-bottom: 0;
      }
    }

    .navbar-menu {
      margin-right: 0;
    }
  }

  .navbar-menu {
    flex: 1;
    padding-left: 1px;
    box-shadow: none;

    .columns {
      &.is-submenu {
        @include desktop {
          position: absolute;
          top: 100%;
          left: $gap;
          right: $gap;
        }

        .column > .button {
          font-size: clamp(1.375rem, 1.6216vw, 1.5rem);
        }

        &[data-id='user'] {
          // justify-content: flex-end;
        }
      }
    }

    .field {
      &.is-white {
        background: $white;
        border: 1px solid $black;
        padding: 4px 5px;
        border-radius: $radius-large;
      }
    }
  }
}

.columns.nav-grid {
  margin-bottom: 0 !important;

  .column {
    display: flex;
    margin-bottom: -1px;
    margin-left: -1px;

    > .button {
      line-height: 1;
      flex: 1;

      &:not(.is-static) {
        z-index: 1;
      }

      &.is-static {
        text-align: left;
        justify-content: flex-start;
        border-radius: 10px;
        white-space: normal;
      }

      &.is-light {
        color: $black;

        &.is-active {
          background: $primary;
          color: $white;
        }
      }

      &:not(.is-static):not(.is-light) {
        &:hover {
          background: $black;
          color: $white;
        }
      }
    }
  }
}
