#notification-container {
  position: fixed;
  width: 100%;
  z-index: 31;
  bottom: 0;

  @include tablet {
    bottom: 30px;
    right: 30px;
    width: 450px;
  }

  @include desktop {
    bottom: 60px;
    right: $gap;
  }

  .notification {
    font-weight: $weight-medium;
    border-radius: 0;

    .buttons {
      .button {
        height: 40px;
        flex: 1;
      }
    }

    &:not(:last-child) {
      margin-bottom: 0;
      border-bottom: 1px solid $black;
    }

    @include tablet {
      &:not(:last-child) {
        border-bottom: 0;
        margin-bottom: 8px;
      }

      border-radius: $radius-medium;
    }
  }
}
