.map-component {
  isolation: isolate;

  @include mobile {
    // margin-left: -$gap;
    // margin-right: -$gap;
  }

  .map {
    border-radius: $radius;
    border: 1px solid $border;
    height: 560px;

    .leaflet-div-icon {
      border: 0;
      background: transparent;

      a {
        color: $primary;

        &:hover svg circle {
          fill: $primary;
        }
      }
    }

    .leaflet-control-attribution {
      opacity: 0.5;

      a {
        color: $grey-darker;
      }

      &:hover {
        opacity: 1;

        a {
        }
      }
    }

    .leaflet-tooltip {
      padding: 3px 8px;
      border-color: $black;
      font-size: $size-9;
      box-shadow: none;

      &:before {
        display: none;
      }
    }
  }
}
