.event-date {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .icon {
    margin: 0 10px;
    font-size: $size-5;
    color: $black;
  }

  .button {
    padding-left: 0.6em;
    padding-right: 0.6em;
  }
}

.icon {
  line-height: 1;
  transition: transform 0.15s;

  &.is-45 {
    transform: rotate(45deg);
  }

  &.is--45 {
    transform: rotate(-45deg);
  }
}

#notification-container {
  .notification {
    @include tablet {
      border-radius: $radius;
    }
  }
}

.has-no-radius {
  border-radius: 0;
}

.has-radius-small {
  border-radius: $radius-small;
}

.has-radius-control {
  border-radius: $radius-control;
}

.is-hash-placeholder {
  background-image: linear-gradient(
    -45deg,
    $border 0%,
    $border 5%,
    transparent 5%,
    transparent 45%,
    $border 45%,
    $border 55%,
    transparent 55%,
    transparent 95%,
    $border 95%,
    $border 100%
  );

  background-size: 8px 8px;
  background-color: $white;
}

.table {
  td,
  th {
    vertical-align: middle;
  }
}

.block-accordion {
  .accordion-trigger {
    white-space: normal;
    justify-content: space-between;

    &.is-active + .accordion-body {
      display: block;
    }
  }

  .accordion-body {
    display: none;
    isolation: auto;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
