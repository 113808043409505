footer {
  &.footer {
    margin-top: 40px;

    nav.navbar {
      padding-top: 0;
      z-index: 1;
    }

    .navbar-brand {
      min-height: none;

      svg {
        width: 90px;
        height: 33px;
      }
    }

    .has-dummy {
      .button {
        display: flex;

        &.is-first {
          max-width: none;
          margin-right: -59px;
        }

        &.is-dummy {
          margin-top: -1px;
          background-color: $grey-light;
          // background-size: 8px 8px;
        }

        &.is-top {
          position: relative;
          border: 0;
          border-radius: 0;
          width: 120px;
          height: 120px;
          background: url('@/betacity/images/footer-button.svg') no-repeat;
          background-size: 100%;
          flex-direction: column;
          justify-content: space-between;
          padding: 16px;

          .icon {
            margin: 0 5px 0 auto;
            width: 1em;
            height: 1em;
            transition: transform 0.15s;
          }

          &:hover {
            color: $primary;
            .icon {
              // transform: translateY(-6px);
            }
          }
        }
      }
    }
  }

  &.is-articles {
  }
}
