// @import 'bulma/sass/utilities/_all.sass';
// @import 'bulma/sass/form/shared.sass';

$checkbox-border-radius: $radius-small;
$checkbox-border-width: 1px;
$checkbox-size: 1em;
$checkbox-border-color: $grey-darker;
$radio-size: 1em;
$loading-background: rgba(247, 247, 247, 0.7);

$datepicker-item-color: $grey-darker;
$datepicker-item-disabled-color: $grey;

@import 'buefy/src/scss/utils/_all.scss';
@import 'buefy/src/scss/components/_checkbox.scss';
@import 'buefy/src/scss/components/_datepicker.scss';
@import 'buefy/src/scss/components/_loading.scss';
@import 'buefy/src/scss/components/_modal.scss';
@import 'buefy/src/scss/components/_radio.scss';
@import 'buefy/src/scss/components/_upload.scss';

.loading-overlay .loading-icon:after {
  border-left-color: $primary;
  border-bottom-color: $primary;
}

.b-checkbox.checkbox {
  display: flex;
  line-height: 1.5;
  align-items: flex-start;

  @include tablet {
    line-height: 1.2;
  }

  &:hover {
    color: $primary;
  }

  input[type='checkbox']:focus + .check {
    box-shadow: none !important;
  }

  .check {
    background-size: 14px 14px !important;
    margin-top: 4px;

    @include tablet {
      margin-top: 1px;
    }
  }

  .control-label {
    padding-left: 10px;
  }
}

.b-radio.radio {
  display: flex;
  line-height: 1.5;
  align-items: flex-start;

  @include tablet {
    line-height: 1.2;
  }

  &:hover {
    color: $primary;
  }

  input[type='radio'] {
    & + .check {
      border-width: 1px;
      border-color: $grey-darker;

      @include tablet {
        margin-top: 1px;
      }
    }

    &:focus + .check {
      box-shadow: none !important;
    }
  }

  .control-label {
    padding-left: 10px;
  }
}

.dropdown {
  &.is-expanded {
    width: 100%;
  }

  .dropdown-trigger {
    width: 100%;

    > .button {
      width: 100%;
      justify-content: space-between;
      border-radius: $radius-control;
      padding-left: 20px;
      padding-right: 20px;

      @include mobile {
        span:first-child {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      > .icon {
        flex: none;
        color: $black;
      }

      .is-xs {
        &:hover {
          background: $primary;
          color: $white;
        }
      }
    }
  }

  &.is-active {
    .dropdown-trigger > .button {
      border-color: $black;
    }
  }

  .dropdown-menu {
    min-width: 100%;
  }

  .dropdown-content {
    border: 1px solid $black;
    max-height: max(40vh, 400px);
    overflow: auto;

    .dropdown-item {
      font-size: $size-6;
      padding: 8px 20px;
      white-space: normal;
    }
  }
}

.datepicker {
  .dropdown .dropdown-content {
    padding: 0;
    max-height: none;

    .dropdown-item {
      padding: 10px;
    }
  }

  .datepicker-header {
    border: 0;
    margin: 0;

    .is-nav {
      border-radius: $radius-control;
      padding: 10px;
      border: 1px solid $grey-light;

      ::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .datepicker-table {
    border-spacing: 8px;

    .datepicker-header {
      .datepicker-cell {
        font-weight: $weight-normal;
        font-size: $size-9;
        color: $primary;
      }
    }

    .datepicker-body {
      .datepicker-cell {
        padding: 0;

        span {
          aspect-ratio: 1;
          display: inline-block;
          width: 32px;
          line-height: 32px;

          @include tablet {
            width: 40px;
            line-height: 40px;
          }
        }

        border-radius: $radius-large !important;

        &.is-selectable.is-within-hovered-range {
          &.is-last-hovered,
          &.is-first-hovered {
            color: $grey-darker;
          }
        }

        &.is-selected.is-within-selected {
        }
      }
    }
  }

  .datepicker-footer {
    border: 0;
    margin: 0;
  }
}

.modal {
  .button.is-exit {
    position: fixed;
    top: 20px;
    right: 20px;

    @include tablet {
      top: 60px;
      right: 60px;
    }
  }

  @include tablet {
    .modal-content {
      width: $modal-content-width;
    }
  }

  &.is-full-screen {
    > .animation-content,
    > .animation-content > .modal-card {
      background-color: $white;
    }

    .modal-content {
      padding: 20px;
      font-size: $size-6;
    }

    .datepicker {
      .datepicker-table {
        max-width: 100%;
      }

      .dropdown-menu {
        position: static;
        .dropdown-content {
          border: 0;

          .dropdown-item {
            padding: 0;
          }
        }
      }
    }
  }
}

.field {
  .label {
    font-size: $size-7;
    margin-bottom: 0.25rem;

    & + .content {
      max-width: 500px;
    }
  }

  .content {
    margin-bottom: 1rem;
  }

  .input,
  .textarea {
    padding-left: 20px;
    padding-right: 20px;

    &:not(.is-rounded) {
      border-radius: $radius-control;
    }
  }

  .help,
  .error {
    margin-top: 10px;

    &.counter {
      font-size: $size-9;
      // margin-top: 0;
      right: 0;
      position: absolute;
    }
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &.is-required {
    .label {
      position: relative;

      &:after {
        content: '*';
        display: inline-block;
        color: $primary;
        font-size: $size-6;
        position: absolute;
        top: 0;
        line-height: 1;
        margin-left: 2px;
      }
    }
  }

  &.has-errors {
    .box.is-checklist,
    .upload-container,
    .control .input,
    .control .textarea,
    .dropdown-trigger > .button {
      border: 1px solid $danger;
    }

    .error {
      color: $danger;
      font-size: $size-9;
      font-style: italic;
    }
  }

  &.is-section {
    &:not(:first-child) {
      margin-top: 60px;
    }
  }

  .box.is-checklist {
    border: 1px solid $white;
    padding: 30px 24px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 0;
  }
}
