.button {
  max-width: 100%;
  border-radius: $radius;
  padding-left: 16px;
  padding-right: 16px;

  span.text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.is-disabled {
    pointer-events: none;
  }

  &.is-static {
    .button {
      background: $button-static-background-color;
    }
  }

  &.is-lighter {
    border: 1px solid $grey-light;

    @include desktop {
      &:hover {
        border-color: $grey-light;
        background: $white;

        .icon.is-primary-hover {
          color: $primary;
        }

        &.is-primary-hover {
          color: $primary;
        }
      }
    }
  }

  &.is-primary-hover:not(.is-lighter):hover {
    border-color: $primary;
    background: $primary;
    color: $white;
  }
}

.button,
.input {
  &.is-square {
    width: $control-height;
    padding-left: 0;
    padding-right: 0;
  }

  &.is-xs {
    height: 20px;
    font-size: $size-8;

    &:not(.is-rounded) {
      border-radius: $radius-small;
    }

    &.is-square {
      width: 20px;
    }
  }

  &.is-small {
    height: 30px;

    &.is-square {
      width: 30px;
    }
  }

  &.is-medium {
    height: 50px;

    &.is-square {
      width: 50px;
    }
  }

  &.is-large {
    height: 60px;

    &.is-square {
      width: 60px;
    }
  }

  &.is-xl {
    height: 80px;
    white-space: normal;

    &.is-square {
      width: 80px;
    }

    .button {
      flex: none;
    }

    &.is-bordered:hover {
      .button.is-square {
        color: $white;
        border-color: $white;
        background: $primary;
      }
    }
  }

  &.is-bordered {
    border-color: $black;

    &:hover,
    &:focus,
    &.is-active {
      border-color: $black;
    }
  }

  &.is-wide {
    min-width: 270px;
    padding-left: 30px;
    padding-right: 30px;
  }

  &.is-document {
    width: 100%;
    min-height: 70px;
    height: auto;
    white-space: normal;
    justify-content: space-between;
    padding: 10px;
    text-align: left;
    overflow: hidden;
    background: $grey-light;
    border-radius: $radius-control;
    border-color: transparent;

    .button {
      flex: none;
    }

    > span {
      padding: 5px 10px;
      hyphens: auto;
      word-wrap: break-word;
    }

    .icon {
      margin: 0 !important;
      display: none;
    }

    &:hover {
      background: $black;
      color: $white;

      .button {
        border-color: $primary;
        background: $primary;
        color: $white;

        .file-extension {
          display: none;
        }

        .icon {
          display: inline-flex;
        }
      }
    }
  }
}

.buttons {
  border-radius: $radius-control;

  .button {
    margin-bottom: 0.625rem;

    &:not(:last-child):not(.is-fullwidth) {
      margin-right: 0.625rem;
    }
  }

  &:last-child {
    margin-bottom: -0.625rem;
  }

  &.is-gapless {
    &:last-child {
      margin-bottom: 0;
    }

    &:not(:last-child) {
      margin-bottom: $block-spacing;
    }

    .button {
      margin-bottom: -1px;
    }

    .button:not(:last-child) {
      margin-right: -1px;
    }
  }

  &.is-vertical-desktop {
    @include desktop {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.field {
  &.is-search {
    min-width: 220px;

    input {
      padding-right: 0;
      padding-left: 20px;
      color: $black;
      border-right: 0;

      &::placeholder {
        color: inherit;
      }
    }

    .button {
      border-left: 0;
    }

    &.is-black {
      input,
      .button {
        background: black;
        color: $white;
      }
    }

    &.is-primary {
      input,
      .button {
        background: $primary;
      }
    }
  }

  &.is-grouped {
    border-radius: $radius-control;
    display: inline-flex;
    padding: 10px;
    background: $grey-light;
  }
}
