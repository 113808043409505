html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;

  main {
    flex: 1;
  }
}

.image {
  img {
    &.is-contain {
      object-fit: contain;
    }

    &.is-cover {
      object-fit: cover;
    }

    &.is-rounded {
      border-radius: $radius-medium;
    }

    &.is-overlay {
      height: 100%;
    }
  }
}

.columns {
  --columnGap: $column-gap;

  &.is-scrollable {
    @include touch {
      overflow: auto !important;
      flex-wrap: nowrap !important;
    }
  }

  &.is-variable {
    margin-top: calc(-1 * var(--columnGap));
    margin-bottom: calc(-1 * var(--columnGap));

    &:not(:last-child) {
      margin-bottom: calc(1.5 * var(--columnGap));
    }

    > .column {
      padding: var(--columnGap);
    }
  }

  &.has-fullheight-box {
    & > .column > .box {
      height: 100%;
    }
  }

  .column {
    @include mobile {
      &.is-second-mobile {
        order: 2;
      }
    }
  }
}

.icon,
.button .icon,
.button .icon.is-small,
.button .icon.is-medium,
.button .icon.is-large {
  width: 1em;
  height: 1em;

  svg,
  img {
    width: 100%;
    height: 100%;
    outline: none;
  }
}

.cookie-group {
  &:not(:last-child) {
    padding-bottom: 20px;
    margin-bottom: 24px;
    border-bottom: 1px solid $border;
  }
}
