.box {
  isolation: isolate;

  &.has-border {
    border: 1px solid $grey-light;
  }

  &.is-2by3 {
    aspect-ratio: 2/3;
    max-width: 100%;
  }

  &.is-3by2 {
    aspect-ratio: 3/2;
    max-width: 100%;
  }

  &.is-header {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .box-info {
      padding: 2px 16px 21px 14px;
    }

    @include mobile {
      box-shadow: $grey-light 2px 2px 16px;

      &.has-carousel {
        padding-top: 80px;
      }

      .is-mobile-menu {
        margin: 0 -26px 10px -24px;
      }
    }

    .field-list {
      .field {
        &:not(:last-child) {
          border-bottom: 1px solid $grey;
          padding: 0 20px 10px;
          margin-bottom: 10px;
          margin-left: -10px;
          margin-right: -10px;
        }

        .image img {
          border-radius: $radius-control;
        }

        .is-size-9 {
          color: #9e9e9e;
          margin: 16px 0 7px;
        }
      }
    }
  }

  &.is-cta {
    position: relative;
    padding: 10px 20px 20px;
    aspect-ratio: 1.5;
    display: flex;
    flex-direction: column;
    border: 1px solid $black;
    color: $black;
    max-width: 100%;
    border-radius: $radius-control;

    h3.is-size-5,
    h3.is-size-4 {
      max-width: 220px;
      margin-top: auto;

      &:first-of-type {
        padding-top: 60px;
      }
    }

    .button {
      position: absolute;
      top: 20px;

      &.is-right {
        right: 20px;
      }

      &.is-left {
        left: 20px;
      }
    }

    &:is(a):hover {
      .button {
        background: $primary;
        color: $white;
        border-color: $primary;
      }
    }

    footer & {
      border: 1px solid $black;
    }

    &.is-back {
      .button.is-right {
        left: 20px;
        right: auto;

        .icon {
          transform: rotate(-90deg);
        }
      }
    }

    &.is-square {
      aspect-ratio: 1;
    }

    &.is-gallery {
      overflow: hidden;

      .image {
        position: absolute;
        z-index: -1;
        opacity: 0.2;
      }
    }

    &.is-vote-award {
      background: $primary;
      border-color: $primary;
      color: $white;

      .button {
        border-color: $white;
      }

      .image {
        position: absolute;
        right: 20px;
        top: 20px;

        svg {
          height: 60px;
          width: 62px;
        }
      }

      h3 {
        padding-top: 80px;
      }
    }

    &.is-dark {
      background: $black;
      color: $white;
      width: 100%;

      &:is(a):hover {
        .button.is-primary {
          color: $white;
          border-color: $white;
          background: transparent;
        }
      }
    }
  }

  &.is-blended {
    display: flex;
    position: relative;
    background: $primary;
    isolation: isolate;

    .image {
      flex: 1;
      display: flex;
      height: 340px;
    }

    header {
      z-index: 1;
      position: absolute !important;
      top: 20px;
      left: 20px;
    }

    footer {
      z-index: 1;
      position: absolute;
      bottom: 20px;
      right: 20px;
      left: 20px;
    }

    &:not(:hover) {
      .is-arrow {
        display: none;
      }
    }

    &:hover {
      .image {
        mix-blend-mode: screen;
        filter: grayscale(1);
      }

      .button.is-black-hover {
        background: $black;
        color: $white;
      }

      .button.is-primary-hover {
        border-color: $black;
        background: $primary;
        color: $white;
      }
    }
  }

  &.is-event {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: $grey-light;
    border: 1px solid $grey-light;

    header {
      position: relative;

      .buttons {
        z-index: 1;
        position: absolute;
        top: 10px;
        left: 10px;
      }

      &.is-imageless {
        .buttons {
          position: static;
        }
      }

      img {
        border-radius: 10px;
      }
    }

    .box-info {
      margin-top: 14px;
      flex: 1;

      .box-title {
        display: block;
        color: $black;
        margin-bottom: 21px;
      }
    }

    footer {
      margin-top: 18px;
    }

    &:not(:hover) {
      .box-info {
        .button.is-primary {
          border-color: $black;
          background: $white;
          color: $primary;
        }
      }
    }

    &:hover {
      background: $white;
      border-color: $grey;

      header {
        .button.is-white:not(:hover) {
          background: $black;
          color: $white;
        }
      }

      .box.is-prize {
        color: $white;
      }
    }
  }

  &.is-person {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: $grey-light;
    border: 1px solid $grey-light;

    .image {
      .button {
        position: absolute;
        top: 10px;
        right: 10px;

        &:hover {
          background: $primary;
          color: $white;
        }
      }

      img {
        filter: grayscale(1);
      }
    }

    .box-info {
      flex: 1;
      padding: 15px 20px;
    }

    footer {
      padding: 0 20px 20px;
    }

    &:hover {
      border: 1px solid $grey;
      background: $white;

      footer .button.is-white {
        background: $grey;

        &:hover {
          background: $primary;
          color: $white;
        }
      }
    }
  }

  &.is-logo {
    background: $grey-light;
    padding: 1.5rem;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }

    &:hover {
      background: $grey;
    }
  }

  &.is-link {
    padding: 20px;
    background: $grey-light;
    border: 1px solid $grey-light;

    &:hover {
      border: 1px solid $grey;
      background: $white;

      .button {
        background: $primary;
        color: $white;
      }
    }
  }

  &.is-page {
    background: $grey-light;
    padding: 1rem 1.25rem;
    border: 1px solid transparent;
    position: relative;
    display: flex;
    flex-direction: column;

    .button.is-absolute {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
    }

    .image img {
      border-radius: $radius-control;
      width: 120px;
      height: 120px;
    }

    &:hover {
      background: $white;
      border-color: $grey;

      .button {
        &.is-absolute {
          background: $primary;
          color: $white;
          border-color: $primary;
        }
      }
    }

    &:not(:hover) {
      .button.is-lighter {
        background: $white;
        border-color: $white;
      }
    }
  }

  &.is-award-category {
    .image {
      svg {
        width: 120px;
        height: 120px;

        path {
          fill: currentColor;
          stroke: transparent;
        }
      }

      &.is-medium {
        svg {
          width: 80px;
          height: 80px;
        }
      }

      &.is-small {
        svg {
          width: 60px;
          height: 60px;
        }
      }
    }

    .image.is-hoverable svg {
      &:first-child {
        color: $black;
      }

      &:last-child {
        color: $primary;
        display: none;
      }
    }

    &:hover,
    &.is-active,
    &.is-black {
      .image.is-hoverable svg {
        &:last-child {
          display: inline-block;
        }

        &:first-child {
          display: none;
        }
      }
    }

    &.has-label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      padding: 20px 12px 12px;
      position: relative;

      .image {
        margin-bottom: 16px;
      }

      .tag {
        position: absolute;
        right: -10px;
        top: -10px;
        color: #7a7b81;
        width: 24px;
        height: 24px;
        background: $white;
      }

      &:is(a) {
        &:hover,
        &.is-active {
          background: $grey-lighter;
        }

        &.is-black {
          background: $black;
          color: $white;

          .tag {
            color: $white;
            background: $primary;
          }
        }
      }
    }
  }

  &.is-prize {
    justify-content: center;
    align-items: center;
    display: flex;
    color: $grey-light;
    border-radius: $radius-control;
    padding: 4px;
    font-size: 56px;
    line-height: 40px;
    width: 60px;
    height: 60px;
    background: $white;
    margin-left: auto;
    margin-right: auto;

    &.is-big {
      width: auto;
      height: auto;
      aspect-ratio: 2/3;
      color: $white;

      .is-title {
        font-size: 144px;
        line-height: 100px;
      }
    }

    &.is-public {
      font-size: 40px;
      color: $primary;

      .box.is-event:hover & {
        background: $primary;
        color: $white;
      }
    }
  }

  &.is-awards-results {
    padding: 10px;

    .block-accordion {
      .accordion-trigger {
        font-weight: $weight-medium;

        &:hover,
        &.is-active,
        &.is-always-active {
          background: transparent;
          color: inherit;
        }
      }

      &.is-award-category {
        > .accordion-trigger {
          padding: 16px 10px 16px 18px;

          .image {
            margin-right: 16px;

            svg {
              width: 30px;
              height: 30px;
            }
          }

          .tag {
            aspect-ratio: 1;
            padding: 0;
          }

          &:hover,
          &.is-active,
          &.is-always-active {
            svg {
              color: $primary;
            }

            .tag {
              color: $white;
            }
          }

          &:hover {
            .tag {
              background: $black;
            }
          }
        }

        &:hover {
          background: $grey-lighter;
        }

        &:has(> .button.is-active),
        &:has(> .button.is-always-active) {
          background: $black;
          color: $white;

          .tag {
            background: $primary;
          }
        }

        > .accordion-body {
          padding: 0 40px 20px;

          .block-accordion:not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }

      &.is-award-subcategory {
        > .accordion-body {
          padding: 0 15px 10px;

          > .button:not(:last-child) {
            margin-bottom: 2px;
          }
        }

        &:has(> .button.is-active),
        &:hover {
          background: $white;
          color: $primary;
        }
      }
    }
  }

  &.is-offset {
    @include mobile {
      background-color: transparent;
      border: 0;
    }

    @include tablet {
      padding: 34px 40px;

      h1 {
        max-width: 460px;
      }

      .is-limited {
        width: auto !important;
        padding: 0 !important;
      }
    }
  }

  &.is-shop-item {
    padding: 20px;
    border: 1px solid $white;

    .box-info {
      position: relative;
      display: flex;
      flex-direction: column;

      @include tablet {
        padding-left: 30px;
      }
    }

    &:hover {
      border-color: $grey;

      .button.is-bordered {
        border-color: $primary;
        background: $primary;
        color: $white;
      }
    }
  }
}
