@import '@/fonts/style.css';

.title {
  // overflow-wrap: break-word;
  hyphens: auto;
  word-wrap: break-word;
  color: $black;

  &.is-extra {
    margin-bottom: -24px;
    margin-top: 40px;

    @include tablet {
      margin-top: 60px;
    }
  }
}

.is-underlined-hover:hover {
  text-decoration: underline;
}

.is-strikethrough {
  text-decoration: line-through !important;
}

.is-italic {
  // font-style: italic;
}

a {
  &.has-text-primary:hover {
    color: $primary !important;
    text-decoration: underline;
  }

  &.is-underlined:hover {
    color: $primary;
  }
}

$ls-1: -2px;
$ls-2: -1px;
$ls-3: -2px;
$ls-4: normal;
$ls-5: -1px;
$ls-6: normal;
$ls-7: normal;
$ls-8: normal;
$ls-9: normal;
$letter-spacings: $ls-1 $ls-2 $ls-3 $ls-4 $ls-5 $ls-6 $ls-7 $ls-8 $ls-9;

// set line-heights / letter-spacings corresponding to sizes
@for $i from 1 through length($line-heights) {
  $lh: nth($line-heights, $i);
  $ls: nth($letter-spacings, $i);

  .is-size-#{$i} {
    line-height: #{$lh};
    letter-spacing: #{$ls};
  }
}
