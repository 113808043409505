header {
  &.is-main {
    margin-top: 20px;

    section {
      &.is-full {
        margin-top: -20px;
        margin-left: -$gap;
        margin-right: -$gap;

        .image {
          height: 100%;

          @include fullhd {
            img {
              border-radius: 0 0 $radius $radius;
            }
          }
        }
      }

      &.is-full-mobile {
        @include mobile {
          margin: 20px -20px 0;

          > .columns {
            min-height: calc(100vh - 140px);
            min-height: calc(100svh - 140px);

            > .column {
              padding: 0;

              &:first-child {
                flex: 1;
                display: flex;
                flex-direction: column;
              }

              .box.is-header {
                flex: 1;
                border-radius: 0 0 $radius $radius;
                padding-bottom: 20px;
              }

              > .image,
              .image.is-header {
                aspect-ratio: 3/2;
                padding-top: 0;

                img {
                  border-radius: $radius $radius 0 0;
                }
              }
            }
          }
        }
      }

      .columns.has-fullheight-box {
        .column > .image {
          @include tablet {
            min-height: 100%;
          }
        }
      }
    }
  }

  &.is-submission {
    .box,
    img.is-rounded {
      border-radius: $radius-control;
    }
  }
}
